import axios from "../util/Api";

import "./ApproveList.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../Loading/Loading";
import moment from "moment";
import { Table, Button, notification, Alert, Result, Checkbox,Spin } from "antd";
import {
  UserAddOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const withReactContent = require("sweetalert2-react-content");

const ApproveList = () => {
  const [error, setError] = useState(null);
  const [code, setCode] = useState("0");
  const [returnMessage, setReturnMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPages] = useState();
  const [pageNumber, setpageNumber] = useState([]);
  const [SearchKey, SetSearchKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const showLoading = () => {
    setLoading(true);
  };

  const hideLoading = () => {
    setLoading(false);
  };
  const handleRowSelect = (selectedRowKeys) => {
    setSelectedRows(selectedRowKeys);
  };

  const handleApproveSelected = async () => {
    const successRows = [];
    showLoading();
    for (const id of selectedRows) {
      const response = await handlePostMultiApplove(id, "Approve");
      if (response.returncode === "0000") {
        successRows.push(id);
      }
    }
    hideLoading();

    notification.info({
      message: "Infomation",
      description: `Sent ${selectedRows.length} rows. ${successRows.length} rows were approved successfully.`,
      placement: "top",
    });

    refreshPage();

    console.log(
      `Sent ${selectedRows.length} rows. ${successRows.length} rows were approved successfully.`
    );
  };

  const handleDenySelected = async () => {
    const successRows = [];
    showLoading();
    for (const id of selectedRows) {
      const response = await handlePostMultiApplove(id, "Deny");
      if (response.returncode === "0000") {
        successRows.push(id);
      }
    }
    hideLoading();

    notification.info({
      message: "Infomation",
      description: `Sent ${selectedRows.length} rows. ${successRows.length} rows were denied successfully.`,
      placement: "top",
    });

    refreshPage();
    console.log(
      `Sent ${selectedRows.length} rows. ${successRows.length} rows were denied successfully.`
    );
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const url = "/vms/pendingtask";
    const params = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { params })
      .then((response) => response)
      .then(
        (result) => {
          const res = result.data;

          setCode(res.returncode);
          if (res.returncode === "0000") {
            // console.log(res);
            setIsLoaded(true);
            setItems(res["Tasklist"]);

            setpageNumber(res["PageNo"]);
            setTotalPages(res["Page Max"]);
            setTotalRecord(res["Record"]);

            if (result.data.Record === "0") {
              setItems([]);
            }
            // setPage(res["Record"]);
          } else {
            const message = res["returnmessage"];
            // console.log(message);
            setReturnMessage(message);
            setIsLoaded(true);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          <Alert
            message="Error"
            description="This is an error message about copywriting."
            type="error"
            showIcon
          />;
        }
      );
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAlertClose = () => {
    navigate("/");
  };

  const refreshPage = () => {
    const token = localStorage.getItem("token");
    const url = "/vms/pendingtask";
    const params = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { params })
      .then((response) => response)
      .then(
        (result) => {
          const res = result.data;

          setCode(res.returncode);
          if (res.returncode === "0000") {
            // console.log(res);
            setIsLoaded(true);
            setItems(res["Tasklist"]);
            setpageNumber(res["PageNo"]);
            setTotalPages(res["Page Max"]);
            setTotalRecord(res["Record"]);

            if (result.data.Record === "0") {
              setItems([]);
            }
            // setPage(res["Record"]);
          } else {
            const message = res["returnmessage"];
            // console.log(message);
            setReturnMessage(message);
            setIsLoaded(true);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          <Alert
            message="Error"
            description="This is an error message about copywriting."
            type="error"
            showIcon
          />;
        }
      );
  };

  const CreateUserButton = () => (
    <Button
      type="primary"
      icon={<SyncOutlined />}
      onClick={() => refreshPage()}
    >
      Refresh
    </Button>
  );

  const showModal = (BadgeNumber) => {
    setIsModalOpen(true);
    setSelectedRowKeys([BadgeNumber]);
  };

  const handlePostMultiApplove = (id, action) => {
    console.log(id, action);
    const token = localStorage.getItem("token");
    return axios
      .post("/vms/pendingtask", {
        PendingList: id,
        Mode: action,
        Token: token,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log("error", error);
        return {
          returncode: "error",
          returnmessage: "Error occurred during request",
        };
      });
  };
  const handlePostApplove = (id, action) => {
    console.log(id, action);
    const token = localStorage.getItem("token");
    axios
      .post("/vms/pendingtask", {
        PendingList: id,
        Mode: action,
        Token: token,
      })

      .then((response) => response)
      .then((result) => {
        const res = result.data;
        if (res.returncode === "0000") {
          MySwal.fire({
            html: res.returnmessage,
            icon: "success",
          }).then(() => {
            refreshPage();
          });
        } else {
          MySwal.fire({
            html: <i>{res.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { current } = pagination;
    setPage(current);
  };

  const handleDelete = (id) => {
    showModal(id);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const columns = [
    {
      title: () => (
        <Checkbox
          checked={items && selectedRows.length === items.length}
          onChange={(e) =>
            e.target.checked
              ? handleRowSelect(items.map((item) => item.ID))
              : handleRowSelect([])
          }
        />
      ),
      dataIndex: "ID",
      width: 100,
      render: (_, item) => (
        <Checkbox
          checked={selectedRows.includes(item.ID)}
          onChange={() =>
            handleRowSelect(
              selectedRows.includes(item.ID)
                ? selectedRows.filter((key) => key !== item.ID)
                : [...selectedRows, item.ID]
            )
          }
        />
      ),
    },
    {
      title: "ID",
      dataIndex: "ID",
      width: 100,
      editable: true,
    },
    {
      title: "Badgenumber",
      dataIndex: "Badgenumber",
      width: 150,
      editable: true,
    },
    {
      title: "FirstName",
      dataIndex: "FirstName",
      width: 200,
      editable: true,
    },
    {
      title: "Lastname",
      dataIndex: "Lastname",
      width: 200,
      editable: true,
    },
    {
      title: "Department",
      dataIndex: "Department",
      width: 250,
      editable: true,
    },
    {
      title: "DeviceExtime",
      dataIndex: "DeviceExtime",
      width: 250,
      editable: true,
    },
    {
      title: "Action",
      fixed: "center",
      width: 150,
      render: (_, item) => (
        <div className="userListAction" key={item.ID}>
          <CheckCircleOutlined
            style={{ fontSize: "24px", color: "#67ED70", cursor: "pointer" }}
            onClick={() => handlePostApplove(item.ID, "Approve")}
          />
          <CloseCircleOutlined
            style={{ fontSize: "24px", color: "#D11D1D", cursor: "pointer" }}
            onClick={() => handlePostApplove(item.ID, "Deny")}
          />
        </div>
      ),
    },
  ];

  if (!isLoaded) {
    return (
      <div className="userList">
        <Loading />
      </div>
    );
  } else if (code !== "0000") {
    return (
      <div className="userList">
        <Result
          status="404"
          title={code}
          subTitle={returnMessage}
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </div>
    );
  } else {
    return (
      <div className="userList">
        <div className="container">
          <div className="input-container">
            <div className="actionButtonsContainer">
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                onClick={handleApproveSelected}
                disabled={selectedRows.length === 0}
              >
                Approve Selected
              </Button>
              <Button
                type="primary"
                icon={<CloseCircleOutlined />}
                onClick={handleDenySelected}
                disabled={selectedRows.length === 0}
              >
                Deny Selected
              </Button>
            </div>
          </div>
          <div className="input-container">
            <CreateUserButton />
          </div>
        </div>
        <div className="input-wrap"></div>
        <Spin spinning={loading}>
          <div className="container">
            <Table
              columns={columns}
              dataSource={items}
              pagination={{
                position: ["bottomCenter"],
                pageSize: 13,
                showSizeChanger: false,
                current: page,
                // total: dataSource.length,
              }}
              onChange={handleTableChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
};

export default ApproveList;
