import { useState, useContext, useEffect } from "react";
import { Layout, Button } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "./util/Api";
const withReactContent = require("sweetalert2-react-content");
const Swal = require("sweetalert2");

const MySwal = withReactContent(Swal);

const TopBar = () => {
  const { Header } = Layout;
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);

  const handleLogout = () => {
    axios
      .post("/vms/logout", {
        Token: localStorage.getItem('token')
      })

      .then((response) => response)
      .then((result) => {
        const res = result.data;
        if (res.returncode === "0000") {
          MySwal.fire({
            html: "Logout Success",
            icon: "success",
          }).then((value) => {
            localStorage.clear();
            navigate("/");
          });
        } else {
          MySwal.fire({
            html: <i>{response.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Header>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Button type="primary" onClick={handleLogout}>
          Logout
        </Button>
      </div>
    </Header>
  );
};

export default TopBar;
