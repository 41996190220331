export const urlapi =
  // process.env.NODE_ENV === "development"
  //   ? `https://www.ymatrix.net:8280`
  //   : `https://www.ymatrix.net:8280`;
  // process.env.NODE_ENV === "development"
  //   ? `https://www.ymatrix.net:8280`
  //   : `https://www.ymatrix.net:8280`;
process.env.NODE_ENV === "development" ? `http://202.22.10.79:8101` : `http://202.22.10.79:8101`;


     