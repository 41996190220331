import axios from "../util/Api";

import "./userList.css";
import { useState, useEffect, useContext } from "react";
import { DeleteOutline } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loading from "../Loading/Loading";
import moment from "moment";
import {
  Input,
  Table,
  Button,
  Tag,
  Alert,
  Result,
  Modal,
  DatePicker,
} from "antd";
import {
  UserAddOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import PDPAConsentPopup from "../PDPAConsentPopup";
import pdfFile from "../PDPA.pdf";
const withReactContent = require("sweetalert2-react-content");

const UserList = () => {
  const [error, setError] = useState(null);
  const [code, setCode] = useState("0");
  const [returnMessage, setReturnMessage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [totalpage, setTotalPages] = useState(-1);
  const [pageNumber, setpageNumber] = useState([]);
  const [SearchKey, SetSearchKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalrecord, setTotalRecord] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [pdfVisible, setPdfVisible] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const url = "/vms/user";
    const params = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { params })
      .then((response) => response)
      .then(
        (result) => {
          const res = result.data;
          setCode(res.returncode);
          if (res.returncode === "0000") {
            // console.log(res);
            setIsLoaded(true);

            setItems(res["Data"]);

            setpageNumber(res["PageNo"]);
            setTotalPages(res["Page Max"]);
            setTotalRecord(res["Record"]);

            if (result.data.Record === "0") {
              setItems([]);
            }
            // setPage(res["Record"]);
          } else {
            const message = res["returnmessage"];
            // console.log(message);
            setReturnMessage(message);
            setIsLoaded(true);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          <Alert
            message="Error"
            description="This is an error message about copywriting."
            type="error"
            showIcon
          />;
        }
      );
  }, []);

  const isPDPAAccepted = localStorage.getItem("PDPA");
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // form.setFieldsValue({ EffectiveDate: date });
  };
  const openPdfViewer = () => {
    setPdfVisible(true);
  };

  const closePdfViewer = () => {
    setPdfVisible(false);
  };

  const handleAlertClose = () => {
    navigate("/");
  };
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePDPAAccept = () => {
    localStorage.setItem("PDPA", "1");
    // ทำสิ่งที่คุณต้องการเมื่อผู้ใช้ยอมรับ PDPA แล้ว เช่น เปลี่ยนสถานะในสถานะในแอพพลิเคชัน
  };

  const CreateUserButton = () => (
    <Button
      type="primary"
      icon={<UserAddOutlined />}
      onClick={() => handleCreate()}
    >
      Add new User
    </Button>
  );

  const CreateRefresh = () => (
    <Button
      type="primary"
      icon={<SyncOutlined />}
      onClick={() => RefreshPage()}
    >
      Refresh
    </Button>
  );

  const RefreshPage = () => {
    const token = localStorage.getItem("token");
    const url = "/vms/user";
    const params = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(url, { params })
      .then((response) => response)
      .then(
        (result) => {
          const res = result.data;
          setCode(res.returncode);
          if (res.returncode === "0000") {
            // console.log(res);
            setIsLoaded(true);

            setItems(res["Data"]);

            setpageNumber(res["PageNo"]);
            setTotalPages(res["Page Max"]);
            setTotalRecord(res["Record"]);

            if (result.data.Record === "0") {
              setItems([]);
            }
            // setPage(res["Record"]);
          } else {
            const message = res["returnmessage"];
            // console.log(message);
            setReturnMessage(message);
            setIsLoaded(true);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          <Alert
            message="Error"
            description="This is an error message about copywriting."
            type="error"
            showIcon
          />;
        }
      );
  };

  const showModal = (BadgeNumber) => {
    setIsModalOpen(true);
    setSelectedRowKeys([BadgeNumber]);
  };
  const handleOk = (id) => {
    const token = localStorage.getItem("token");
    const url = "/vms/deleteuser";

    const params = {
      Authorization: `Bearer ${token}`,
      BadgeNumber: id,
      Mode: 4,
      EffectiveDay: selectedDate.format("YYYY/MM/DD"),
    };
    // console.log(params);
    axios
      .post("/vms/deleteuser", {
        Authorization: `Bearer ${token}`,
        BadgeNumber: id,
        Mode: 4,
        EffectiveDay: selectedDate.format("YYYY/MM/DD"),
        Token: token,
      })

      .then((response) => response)
      .then((result) => {
        const res = result.data;
        // console.log(res);
        if (res.returncode === "0000") {
          MySwal.fire({
            html: res.returnmessage,
            icon: "success",
          }).then((value) => {
            RefreshPage();
          });
        } else {
          MySwal.fire({
            html: <i>{res.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCreate = () => {
    navigate("/newUser");
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { current } = pagination;
    setPage(current);
  };

  const handleDelete = (id) => {
    showModal(id);
  };

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleTagClick = (item) => {
  
    console.log("Tag clicked for user with BadgeNumber:", item.BadgeNumber);

  };

  const filteredRows = searchTerm
    ? items.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : items;

  const dataSource = filteredRows.map((row, index) => ({
    ...row,
    key: index.toString(), // Assigning the index as the key
  }));
  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      width: 100,
      editable: true,
    },
    {
      title: "CardID",
      dataIndex: "CardID",
      width: 150,
      editable: true,
    },
    {
      title: "Badgenumber",
      dataIndex: "BadgeNumber",
      width: 150,
      editable: true,
    },
    {
      title: "FirstName",
      dataIndex: "FirstName",
      width: 200,
      editable: true,
    },
    {
      title: "LastName",
      dataIndex: "LastName",
      width: 200,
      editable: true,
    },
    {
      title: "Company",
      dataIndex: "Company",
      width: 250,
      editable: true,
    },
    {
      title: "Action",
      fixed: "center",
      width: 150,
      render: (_, item) => (
        <div className="userListAction" key={item.index}>
          {item.Status === "Activate" ? (
            <Tag
              icon={<CheckCircleOutlined />}
              color="success"
              onClick={() => handleTagClick(item)} 
              style={{ width: "90px" , cursor:"pointer"}}
            >
              {item.Status}
            </Tag>
          ) : item.Status === "Pending" ? (
            <Tag
              icon={<SyncOutlined spin />}
              color="blue"
              style={{ width: "90px" }}
            >
              {item.Status}
            </Tag>
          ) : item.Status === "Deleting" ? (
            <Tag
              icon={<ExclamationCircleOutlined />}
              color="warning"
              style={{ width: "90px" }}
            >
              {item.Status}
            </Tag>
          ) : (
            <Tag
              icon={<CloseCircleOutlined />}
              color="error"
              style={{ width: "90px" }}
            >
              {item.Status}
            </Tag>
          )}
          <DeleteOutline
            className="userListDelete"
            //onClick={() => showModal(item.BadgeNumber)}
            onClick={() => handleDelete(item.BadgeNumber)}
          />
        </div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setPdfVisible(false); // เมื่อปิด Modal ตั้งค่าให้ pdfVisible เป็น false
  };

  if (!isLoaded) {
    return (
      <div className="userList">
        <Loading />
      </div>
    );
  } else if (code !== "0000") {
    return (
      <div className="userList">
        <Result
          status="404"
          title={code}
          subTitle={returnMessage}
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </div>
    );
  } else {
    return (
      <div className="userList">
        <div className="container">
          {isPDPAAccepted === "0" ||  isPDPAAccepted === null ? (
            <PDPAConsentPopup
              pdfUrl={pdfFile}
              visible={true}
              onClose={handleCloseModal}
              pdfVisible={pdfVisible}
               maskClosable={false} 
            />
          ) : null}

          <div className="input-container">
            <Input
              className="input-text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchTermChange}
              style={{ width: 200 }}
            />
          </div>
          <div className="input-container">
            <CreateUserButton />
          </div>
          <div className="input-container">
            <CreateRefresh />
          </div>
        </div>
        <div className="input-wrap"></div>
        <div className="container">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              position: ["bottomCenter"],

              pageSize: 13,
              showSizeChanger: false,
              current: page,

              total: dataSource.length,
            }}
            onChange={handleTableChange}
          />
        </div>
        <Modal
          title="Delete User."
          open={isModalOpen}
          onOk={() => handleOk(selectedRowKeys[0])}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" type="primary" ghost onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="ok"
              type="primary"
              danger
              ghost
              style={{ width: "75px" }}
              onClick={() => handleOk(selectedRowKeys[0])}
            >
              OK
            </Button>,
          ]}
        >
          {selectedRowKeys.map((key) => {
            const item = items.find((row) => row.BadgeNumber === key);
            if (item) {
              return <p key={key}>BadgeNumber: {item.BadgeNumber}</p>;
            }
            return null;
          })}

          <DatePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD"
            defaultValue={moment()}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Modal>
      </div>
    );
  }
};

export default UserList;
