import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./SignIn";
import UserList from "./UserList/UsersList";
import NewUser from "./NewUser/NewUser";
import React, { useState } from "react";
import DataContext from "./DataContext";
import TopBar from "./TopBar"; // Import the TopBar component
import ApproveList from "./ApproveList/ApproveList";
import PDPA from "./PDPAConsentPopup";
import ChangePassword from "./ChangePasswordModal"
function App() {
  const [auth, setAuth] = useState(null);
  return (
    <DataContext.Provider value={{ auth, setAuth }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/*" element={<AppLayout />} />{" "}
          {/* Render the layout component for other routes */}
        </Routes>
      </BrowserRouter>
    </DataContext.Provider>
  );
}

function AppLayout() {
  return (
    <>
      <TopBar /> {/* Render the TopBar component */}
      <Routes>
        <Route path="/users" element={<UserList />} />
        <Route path="/newUser" element={<NewUser />} />
        <Route path="/approve" element={<ApproveList />} />
        <Route path="/PDPA" element={<PDPA />} />
     
      </Routes>
    </>
  );
}

export default App;
