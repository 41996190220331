import React, { useRef, useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDPAConsentPopup = ({ pdfUrl, visible, onClose , pdfVisible,maskClosable }) => {
  const modalContentRef = useRef(null);
  const documentRef = useRef(null);
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [acceptDisabled, setAcceptDisabled] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (currentPage === numPages) {
      setAcceptDisabled(false);
    }
  }, [currentPage, numPages]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1); // เมื่อโหลด PDF สำเร็จให้ตั้งค่าหน้าแรกเป็น 1
  };
  
  const handleAccept = () => {
    // ใส่โค้ดที่ต้องการเมื่อผู้ใช้กด Accept
    localStorage.setItem('PDPA','1')
    onClose();
  };

  const handleCancel = () => {
    // ใส่โค้ดที่ต้องการเมื่อผู้ใช้กด Cancel
    localStorage.clear();
    navigate('/')
  };

  const onNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
  };

  const onPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Modal
      title={<div style={{ textAlign: "center" }}>PDPA Consent</div>}
      ref={modalContentRef}
      className="modal-content"
      open={pdfVisible}
      maskClosable={maskClosable}
      onCancel={handleCancel}
      footer={[
        <Button
          type="dashed"
          danger
          onClick={handleCancel}
          style={{ width: "100px", marginRight: "10px" }}
        >
          Decline
        </Button>,
        <Button
          type="primary"
          onClick={handleAccept}
          style={{ width: "100px" }}
          disabled={acceptDisabled}
        >
          Accept
        </Button>,

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button onClick={onPrevPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <div
            style={{ margin: "0 10px" }}
          >{`${currentPage} / ${numPages}`}</div>
          <Button onClick={onNextPage} disabled={currentPage === numPages}>
            Next
          </Button>
        </div>,
      ]}
      width={800}
      destroyOnClose
      style={{ overflow: "hidden", top: "0" }}
      bodyStyle={{ overflowY: "hidden", maxHeight: "calc(100vh - 160px)" }}
    >
      <Document
        file={pdfUrl}
        loading="Loading PDF..."
        onLoadSuccess={onDocumentLoadSuccess}
        error="Failed to load PDF"
        onLoadError={(error) => console.log("Error loading PDF:", error)}
        // className="pdf-document"
        // ref={documentRef}
      >
        <Page
          pageNumber={currentPage}
          width={720}
          renderTextLayer={false}
          scale={1}
        />
      </Document>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Button onClick={onPrevPage} disabled={currentPage === 1}>
          Previous
        </Button>
        <div style={{ margin: "0 10px" }}>{`${currentPage} / ${numPages}`}</div>
        <Button onClick={onNextPage} disabled={currentPage === numPages}>
          Next
        </Button>
      </div>
    </Modal>
  );
};

export default PDPAConsentPopup;
