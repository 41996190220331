import { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Result,
  Alert,
  notification 
} from "antd";
import { CameraOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import WebcamCapture from "../WebcamCapture";
import axios from "../util/Api";
import "./newuser.css"; // Import the CSS file
import moment from "moment";
import Loading from "../Loading/Loading";
const Swal = require("sweetalert2");
const withReactContent = require("sweetalert2-react-content");

const NewUserForm = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const { Option } = Select;
  const currentDate = new Date();
  const [form] = Form.useForm();
  const [code, setCode] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [returnMessage, setReturnMessage] = useState("");
  const [company, setCompany] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    const token = localStorage.getItem("token");
    const url = "/vms/department";
    const params = {
      Authorization: `Bearer ${token}`,
      DepartmentID: localStorage.getItem("DepartmentID"),
    };
    axios
      .get(url, { params })
      .then((response) => response)
      .then(
        (result) => {
          const res = result.data;

          if (res.Department[0].hasOwnProperty("SubLevel 2")) {
            const subLevel2 = res.Department[0]["SubLevel 2"];

            setCode(res.returncode);
            if (res.returncode === "0000") {
              setCompany(subLevel2);
              setIsLoaded(true);
              // setPage(res["Record"]);
            } else {
              const message = res["returnmessage"];
              setReturnMessage(message);
              setIsLoaded(true);
            }
          } else {
            Swal.fire({
              title: "Not found department",
              text: "not found department sublevel 2 in this users.",
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/users");
            });
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          <Alert
            message="Error"
            description="This is an error message about copywriting."
            type="error"
            showIcon
          />;
        }
      );
  }, []);

  const options = company.map((entry) => ({
    label: entry["Department Name"],
    value: entry["DepartmentID"],
  }));

  const onFinish = (values) => {
    if (!capturedImage) {
      notification.warning({
        message: 'Warning',
        description: 'Please capture an image before submitting.',
        placement: 'top'
      });
      return;
    }
    const { FirstName, LastName, ...restValues } = values;
    const Token = localStorage.getItem("token");
    const currentDate = new Date();

    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")} 00:00:00`;
    let cardStartDate = null;
    let cardExpireDate = null;
    if (selectedDate) {
      cardStartDate = selectedDate[0].format("YYYY-MM-DD");
      cardExpireDate = selectedDate[1].format("YYYY-MM-DD");
    }

    console.log({
      ...restValues,
      CardStartDate: cardStartDate,
      CardExpireDate: cardExpireDate,
      EffectiveDate: formattedDate,
      DepartmentID: selectedOption,
      FirstName: FirstName,
      LastName:  LastName,
      Photo: capturedImage
        ? capturedImage.substring(capturedImage.indexOf(",") + 1)
        : null,
      DoorGroupID: "1",
      Token: Token,
    });
    axios
      .post("/vms/user", {
        ...restValues,
        CardStartDate: cardStartDate,
        CardExpireDate: cardExpireDate,
        EffectiveDate: formattedDate,
        DepartmentID: selectedOption,
        FirstName: FirstName,
        LastName:  LastName,
        DoorGroupID: "1",
        Photo: capturedImage
          ? capturedImage.substring(capturedImage.indexOf(",") + 1)
          : null,
        Token: Token,
      })
      .then((response) => response)
      .then((result) => {
        console.log(result);
        const res = result.data;
        if (res.returncode === "0000") {
          MySwal.fire({
            html: <i>{result.data.returnmessage}</i>,
            icon: "success",
          }).then((value) => {
            navigate("/users");
          });
        } else {
          MySwal.fire({
            html: <i>{res.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    form.setFieldsValue({ EffectiveDate: dates });

    const isAfter = dates[0].isAfter(dates[1]);
    // console.log("Is date1 after date2?", isAfter);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(value);
    setSelectedOption(value);
  };

  const formatDateTime = (date) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    return date
      .toLocaleString("en-US", options)
      .replace(/(\d+)\/(\d+)\/(\d+),/, "$3/$1/$2")
      .replace(",", "");
  };
  const [modalVisible, setModalVisible] = useState(false);

  const handleCameraClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleCapture = (imageDataURL) => {
    // console.log(imageDataURL);
    setCapturedImage(imageDataURL);
    setModalVisible(false);
  };

  const labelStyle = {
    width: "100px",
    textAlign: "right",
    paddingRight: "0px",
  };
  if (!isLoaded) {
    return (
      <div className="userList">
        <Loading />
      </div>
    );
  } else if (code !== "0000") {
    return (
      <div className="userList">
        <Result
          status="404"
          title={code}
          subTitle={returnMessage}
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Back Home
            </Button>
          }
        />
      </div>
    );
  } else {
    return (
      <div className="form-container">
        <Form
          form={form}
          style={{
            border: "solid 1px #e6e6e6",
            padding: "40px",
            borderRadius: "15px",
          }}
          name="basic"
          // initialValues={{
          //   remember: true,
          // }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            id: "", // Set the initial value for the "id" field
            name: "", // Set the initial value for the "name" field
            cardid: "", // Set the initial value for the "cardid" field
            mobile: "", // Set the initial value for the "mobile" field
            email: "", // Set the initial value for the "email" field
            EffectiveDate: "", // Set the initial value for the "Effective" field
            company: localStorage.getItem("DepartmentID"), // Set the initial value for the "company" field
          }}
        >
          <div className="image-container">
            {capturedImage ? (
              <img
                src={capturedImage}
                alt="Captured"
                className="captured-image"
                onClick={handleCameraClick}
              />
            ) : (
              <CameraOutlined
                className="camera-icon"
                onClick={handleCameraClick}
              />
            )}
          </div>
          <Form.Item
            className="formItem"
            label="ID"
            name="BadgeNumber"
            rules={[
              {
                required: true,
                message: "Please input your id.",
              },
            ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="formItem"
            label="Firstname"
            name="FirstName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            className="formItem"
            label="LastName"
            name="LastName"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="formItem"
            label="CardID"
            name="cardid"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input cardid.",
            //   },
            // ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="formItem"
            label="Mobile"
            name="Mobile"
            rules={[
              {
                pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                message: "Please enter a valid mobile number",
              },
            ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input type="tel" />
          </Form.Item>
          <Form.Item
            className="formItem"
            label="Email"
            name="Email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Effect Date"
            className="formItem"
            labelAlign="left"
            name="EffectiveDate"
            labelCol={{ style: labelStyle }}
          >
            <RangePicker
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              defaultValue={[
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
              ]}
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Form.Item>
          <Form.Item
            className="formItem"
            label="Company"
            name="CompanyID"
            labelAlign="left"
            labelCol={{ style: labelStyle }}
          >
            <Input
              type="text"
              defaultValue={localStorage.getItem("DepartmentID")}
              disabled
            />
          </Form.Item>

          <Form.Item
            className="formItem"
            label="Department"
            name="DepartmentID"
            labelAlign="left"
            labelCol={{ style: labelStyle }}
            rules={[
              {
                required: true,
                message: "Please select department.",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={options}
              isOpen={isOpen}
              // onMenuOpen={() => setIsOpen}
              // onMenuClose={() => handleMenu("menu1")}
              onChange={handleChange}
              value={selectedOption}
            />
          </Form.Item>

          <Form.Item className="formItem">
            <Button type="primary" htmlType="submit" style={{ width: "80px" }}>
              Submit
            </Button>
            <Button
              danger
              style={{ marginLeft: "10px", width: "80px" }}
              onClick={() => navigate("/users")}
            >
              Back
            </Button>
          </Form.Item>
        </Form>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          title={<div style={{ textAlign: "center" }}>Webcam Capture</div>}
          open={modalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <WebcamCapture
            onCapture={handleCapture}
            width={480}
            height={480}
            onClose={handleModalClose}
          />
        </Modal>
      </div>
    );
  }
};

export default NewUserForm;
