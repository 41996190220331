import React from "react";
import RotateLoader from "react-spinners/RotateLoader";
import "./Loading.css";
import logo from "../Image/logo_central.png";

const Loading = () => {
  return (
    <div className="loader">
      <div className="content">
        <img
          src={logo}
          alt="Description of the image"
          style={{ width: "562px", height: "152px" }}
        />
        <RotateLoader
          color="rgba(44, 36, 191, 0.89)"
          cssOverride={{}}
          loading
          size={20}
          speedMultiplier={0.75}
        />
      </div>
    </div>
  );
};

export default Loading;
