import React, { useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { Button } from "antd";
import { BorderColor } from "@mui/icons-material";

const WebcamCapture = ({ onCapture, width, height, onClose }) => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const capture = useCallback(() => {
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;

    // Make sure the video and canvas elements are loaded
    if (video.readyState === video.HAVE_ENOUGH_DATA && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current frame from the video on the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image data URL from the canvas
      const imageDataURL = canvas.toDataURL("image/png");
      // Pass the image data URL to the onCapture callback
      onCapture(imageDataURL);
    }
  }, [onCapture]);

  const clickClose = () => {
    onClose(); // Call the onClose callback to close the modal
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageDataURL = reader.result;

      onCapture(imageDataURL);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div style={{ borderTop: "1px solid #ccc", paddingTop: "10px" }}>
      <Webcam
        audio={false}
        ref={webcamRef}
        width={width}
        height={height}
        style={{ borderRadius: "10px" }}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button danger onClick={clickClose} style={{ width: "80px" }}>
          Close
        </Button>
        <div>
          <label
            htmlFor="image-upload"
            style={{
              display: "inline-block",
              marginRight: "10px",
              padding: "6px 12px",
              background: "#1890ff",
              color: "#fff",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Browse Image
          </label>
          <input
            id="image-upload"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          <Button type="primary" onClick={capture} style={{ width: "80px" }}>
            Capture
          </Button>
        </div>
      </div>
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default WebcamCapture;
