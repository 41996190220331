import axios from "./util/Api";
import { useNavigate } from "react-router-dom";
import DataContext from "./DataContext";
import { Modal, Form, Input, Button as Button1, Row, Col } from "antd";
//import { Link } from "react-router-dom";
const React = require("react");
const { useState, useContext } = require("react");
const Swal = require("sweetalert2");
const withReactContent = require("sweetalert2-react-content");
const Avatar = require("@mui/material/Avatar").default;
const Button = require("@mui/material/Button").default;
const CssBaseline = require("@mui/material/CssBaseline").default;
const TextField = require("@mui/material/TextField").default;
const FormControlLabel = require("@mui/material/FormControlLabel").default;
const Checkbox = require("@mui/material/Checkbox").default;
const Link = require("@mui/material/Link").default;
const md5 = require("md5");
const Box = require("@mui/material/Box").default;
const LockOutlinedIcon = require("@mui/icons-material/LockOutlined").default;
const Typography = require("@mui/material/Typography").default;
const Container = require("@mui/material/Container").default;
const { createTheme, ThemeProvider } = require("@mui/material/styles");

function SignIn() {
  const { useForm } = Form;
  const [inputs, setInputs] = useState({});
  const { auth, setAuth } = useContext(DataContext);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [response, setResponse] = useState(null);
  const [user, setUser] = useState("");
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const [form] = useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
 
  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    axios
      .post("/vms/login", {
        USERID: inputs.username,
        PASSWORD: md5(inputs.password),
      })
      .then((response) => response)
      .then((result) => {
        setResponse(result.data);
        const res = result.data;
        console.log(res);
        if (res.returncode === "0000") {
          if (res.changepw === "1") {
            setUser(res.UserName);
            showModal();
          } else {
            MySwal.fire({
              html: "Login Success",
              icon: "success",
            }).then((value) => {
              setResponse(res);
              setAuth(res);
              localStorage.setItem("token", res.Token);
              localStorage.setItem("DepartmentID", res.DepartmentID);

              if (res.UserLevel === "1") {
                navigate("/approve");
              } else {
                navigate("/users");
              }
            });
          }
        } else {
          MySwal.fire({
            html: <i>{result.data.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleChangePassword = async (values) => {
    setIsSubmitting(true);
    axios
      .post("/vms/changepassword", {
        Token: response.Token,
        NewPassword: md5(values.newPassword),
        OldPassword: md5(inputs.password),
      })
      .then((response) => response)
      .then((result) => {
        const res = result.data;
        if (res.returncode === "0000") {
          MySwal.fire({
            html: res.returnmessage,
            icon: "success",
          }).then((value) => {
            hideModal();
          });
        } else {
          MySwal.fire({
            html: <i>{result.data.returnmessage}</i>,
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
    setIsSubmitting(false);
  };
  const defaultValues = {
    username: user, // Set your desired default value here
    newPassword: "", // Set an empty string or other default value
    confirmPassword: "", // Set an empty string or other default value
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 2, bgcolor: "#1976d2", width: 52, height: 52 }}>
            <LockOutlinedIcon sx={{ fontSize: 24 }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Username or E-mail"
              autoComplete="Username or E-mail"
              type="text"
              name="username"
              value={inputs.username || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={inputs.password || ""}
              onChange={handleChange}
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © Ymatrix "}
          <Link color="inherit" href="http://y-matrix.com/">
            Your Website
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
      <Modal
        title="Change Password"
        open={isModalVisible}
        onCancel={hideModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleChangePassword}
          initialValues={defaultValues}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="username"
                label="Username"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  { required: true, message: "Please enter your username" },
                ]}
              >
                <Input value={user} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="newPassword"
                label="New Password"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  { required: true, message: "Please enter a new password" },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                dependencies={["newPassword"]}
                rules={[
                  { required: true, message: "Please confirm your password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Form.Item wrapperCol={{ span: 16 }}>
                <Button1
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Save
                </Button1>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ThemeProvider>
  );
}
export default SignIn;
